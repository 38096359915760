import { useState } from 'react'
import { initialDialog } from '../../main/constants/dialogConstant'

const useDialogState = () => {
    const [dialogState, setDialogState] = useState(initialDialog);

    const handleOpen = (data = null) => setDialogState((pre) => ({ ...pre, isOpen: true, data }));

    const handleClose = () => setDialogState(initialDialog);

    return { dialogState, handleOpen, handleClose };
}

export default useDialogState;