// Footer.js

import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Avatar, Divider, Grid, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <Grid container sx={{ width: '100%', backgroundColor: '#1E1A54', color: '#fff', padding: { lg: '30px', sm: '20px 30px', xs: '20px 20px', position: 'absolute', bottom: 0 } }}>
            <Grid container item xs={12} spacing={3}>
                <Grid item container xs={12} sm={8} justifyContent={'start'}>
                    <Typography display={'flex'} flexWrap={'wrap'} color='gray' fontSize={{ xs: 14, lg: 16 }}>Copyright © 2024<Typography fontSize={{ xs: 14, lg: 16 }} color='#FBAD18'>&nbsp;Awardtiger&nbsp;</Typography> All rights reserved</Typography>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent={{ sm: 'end' }}>
                    <Typography fontSize={{ xs: 14, lg: 16 }} color='gray'>Version 1.0.1</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
