import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import SearchFlightForm from './shared-components/SearchFlightForm'
import { useSelector } from 'react-redux'
import Footer from '../shared-components/Footer'

const Home = () => {
  const state = useSelector((state) => state.home)
  const isSmallHeight = useMediaQuery('(max-height:760px)');
  const { token } = useSelector(state => state.user)
  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'} className='w-full page'>
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ backgroundImage: { xs: `url('images/Banner1.svg')` }, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: { xl: '95vw', lg: '90vw', md: '100vw' },paddingTop : {xs:'1rem',sm:0}, height: {xs:'unset',sm:'100dvh'}, margin: '69px 0px 0px 0px' }}>
          <Grid item display={{ xs: 'none', md: isSmallHeight ? 'none' : 'flex', lg: 'flex' }} sm={7} md={5.5} lg={isSmallHeight ? 4.8 : 5} xl={5} position={'absolute'} top={{ xl: isSmallHeight ? '18%' : '13%', lg: '19%', md: '20%', sm: '15%', xs: '15%' }} textAlign={'center'}>
            <Typography fontWeight={600} lineHeight={isSmallHeight ? '100%' : '117%'} color='#302741' fontSize={{ xl: isSmallHeight ? 50 : 65, lg: isSmallHeight ? 40 : 50, md: 40, sm: 30, xs: 25 }}>Finding The Hidden Award Flights You Really Want. Let's Go</Typography>
          </Grid>
          <Grid item xs={11} md={11} lg={10}>
            <SearchFlightForm state={state} token={token} />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default Home