import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
const RequiresAuth = ({ children }) => {
    const location = useLocation()
    const { token } = useSelector((state) => state.user)
    return (
        token ? children : <Navigate to="/login" state={{ from: location }} />
    )
}
export default RequiresAuth