import { Button, CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';

const FormHandler = ({formRef = null, title ,isEditMode=false, initialValues, validationSchema, onSubmit,children, hideActionButton = false, isLoading = false, submitLabel, secondaryActionButtons }) => {
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values,actions) => {
            if (onSubmit) {
                onSubmit({values, actions})
            }
        },
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        formik.handleSubmit(event);
      };
      
    if(formRef){
        // Assign the Formik instance to the ref
        formRef.current = formik;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>
                {formik.values &&
                    children({
                        formik: formik,
                        isEditMode :isEditMode
                    })}

                {!hideActionButton && (
                    <Box width={1} my={2}>
                        <Grid container justifyContent='flex-end' spacing={2}>
                            {(secondaryActionButtons ?? []).map(button => (
                                <Grid key={button.id} item>
                                    <Button
                                        color={button.color ?? 'secondary'}
                                        variant={button.variant ?? 'outlined'}
                                        onClick={() => button.onClick(formik)}
                                        size='large'
                                    >
                                        {button.isLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            button.label
                                        )}
                                    </Button>
                                </Grid>
                            ))}
                            <Grid item>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    disabled={isLoading}
                                    data-cy='submitButton'
                                >
                                    {isLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        submitLabel ??
                                        `${isEditMode
                                            ? "Update"
                                            : "Create"
                                        } ${title}`
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Grid>
        </form>
    )
}

export default FormHandler



// import * as yup from 'yup'

// secondaryActionButtons?: {
//     id: string
//     label: string
//     color?: 'primary' | 'secondary'
//     variant: 'contained' | 'outlined' | 'text'
//     onClick: (formValues?: any) => void
//     isLoading?: boolean
//   }[]



// const validationSchema = yup.object({
//     company_internal_id: yup.string().nullable(),
//     first_name: yup.string(),
//     last_name: yup.string(),
//     title: yup.string().nullable(),
//     email: yup
//       .string()
//       .email(t('employees.validations.invalidEmailFormat'))
//       .required(t('employees.validations.emailIsRequired')),
//     phone_number: yup.string().nullable(),
//     // .test(
//     //   'matches-phone-number',
//     //   'Must be a valid US phone number.',
//     //   (phone_number: any) =>
//     //     !phone_number ||
//     //     (phone_number &&
//     //       phone_number.match(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/)),
//     // ),
//     location: yup.string(),
//     department: yup.string(),
//     business_unit: yup.string().nullable(),
//     region: yup.string().nullable(),
//     territory: yup.string().nullable(),
//     job_type: yup.string().nullable(),
//     hired_at: yup.date().nullable().typeError(t('invalidDateFormat')),
//     date_of_birth: yup.date().nullable().typeError(t('invalidDateFormat')),
//     gender: yup.string().nullable(),
//     ethnicity: yup.string().nullable(),
//     performance_level: yup.string().nullable(),
//   })
  
//   const bulkEditValidationSchema = yup.object({
//     location: yup.string(),
//     department: yup.string(),
//     business_unit: yup.string().nullable(),
//     region: yup.string().nullable(),
//     territory: yup.string().nullable(),
//     job_type: yup.string().nullable(),
//   })