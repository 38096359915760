import React from 'react'
import FormHandler from '../../shared-components/FormHandler'
import FlightsCheckSection from './FlightsCheckSection'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { addAirportInfo, getMilezData, handleOpenSnackbar, savePreferences } from '../../../store/homeSlice'
import DialogTeleport from '../../shared-components/DataDialog/DialogTeleport'
import useDialogState from '../../../utils/hooks/UseDialogState'
import { Button } from '@mui/material'
import SubscribeDialog from '../dialog/SubscribeDialog'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import { getPreferences } from '../../../store/preferencesSlice'

const SearchFlightForm = (props) => {
    const { token, state } = props
    const navigate = useNavigate()
    const { dialogState: subscribeDialog, handleOpen: handleSubscribeDialogOpen, handleClose: handleSubscribeDialogClose } = useDialogState()
    const dispatch = useDispatch()
    const initialValues = {
        month: dayjs(),
        trip: {
            label: 'One Way',
            value: 'One_Way'
        },
        number_of_passengers: 1,
        flexibleDates: false,
        source: null,
        destination: null,
        arrival_date: null,
        departure_date: dayjs(),
        searchSource: '',
        searchDestination: '',
        class: {
            label: 'Economy',
            value: 'economy'
        },
    }
    const searchValidationSchema = yup.lazy((values) => {
        return yup.object({
            trip: yup.object().typeError('Required').nullable().required(),
            number_of_passengers: yup.number().required(),
            flexibleDates: yup.boolean(),
            source: yup.object().typeError('Required').nullable().required(),
            destination: yup.object().typeError('Required').nullable().required(),
            arrival_date: values.trip?.value == 'Round_Trip' ? yup.date().nullable().required() : yup.date().nullable(),
            departure_date: yup.date().nullable().required(),
            class: yup.object().typeError('Required').nullable().required(),
        })
    })
    const handleSearch = ({ values, actions }) => {
        const milesPayload = {
            source: values.source?.code,
            destination: values.destination?.code
        }
        const milesFormData = new FormData()
        Object.entries(milesPayload).forEach(([key, value]) => {
            milesFormData.append(key, value);
        });
        const airportInfoSourcePayload = {
            state: values?.source?.state,
            city: values?.source?.city,
            country: values?.source?.country,
            airport_code: values?.source?.code,
            airport_name: values?.source?.name
        }
        const airportInfoDestinationPayload = {
            state: values?.destination?.state,
            city: values?.destination?.city,
            country: values?.destination?.country,
            airport_code: values?.destination?.code,
            airport_name: values?.destination?.name
        }
        const airportInfoSourceFormData = new FormData()
        Object.entries(airportInfoSourcePayload).forEach(([key, value]) => {
            airportInfoSourceFormData.append(key, value);
        });
        const airportInfoDestinationFormData = new FormData()
        Object.entries(airportInfoDestinationPayload).forEach(([key, value]) => {
            airportInfoDestinationFormData.append(key, value);
        });
        dispatch(addAirportInfo(airportInfoSourceFormData))
        dispatch(addAirportInfo(airportInfoDestinationFormData))
        dispatch(getMilezData(milesFormData)).then((response) => {
            if (response?.error) {
                dispatch(handleOpenSnackbar({
                    message: 'Something went wrong',
                    type: 'error',
                    open: true
                }))
            } else if (Object.keys(response.payload?.result).length == 0) {
                dispatch(handleOpenSnackbar({
                    message: 'Try Another Source and Destination',
                    type: 'error',
                    open: true
                }))
            }
            else {
                handleSubscribeDialogOpen(values)
                actions.resetForm()
            }
        })
    }
    const handleSubscribeClick = () => {
        const payload = {
            ...subscribeDialog?.data,
            trip: subscribeDialog?.data?.trip.value,
            class: subscribeDialog?.data?.class.value,
            arrival_date: dayjs(subscribeDialog?.data?.arrival_date).format('YYYY-MM-DD'),
            departure_date: dayjs(subscribeDialog?.data?.departure_date).format('YYYY-MM-DD'),
            month: dayjs(subscribeDialog?.data?.month).format('MMMM-YYYY'),
            source: subscribeDialog?.data.source?.code,
            destination: subscribeDialog?.data.destination?.code
        }
        subscribeDialog?.data?.trip?.value == 'One_Way' && delete payload?.arrival_date
        subscribeDialog?.data?.flexibleDates && delete payload?.arrival_date
        subscribeDialog?.data?.flexibleDates && delete payload?.departure_date
        !subscribeDialog?.data?.flexibleDates && delete payload?.month
        delete payload.searchSource
        delete payload.searchDestination
        if (token) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value);
            });
            dispatch(savePreferences(formData)).then((response) => {
                if (response?.error) {
                    dispatch(handleOpenSnackbar({
                        message: response?.error?.message,
                        type: 'error',
                        open: true
                    }))
                } else {
                    dispatch(getPreferences())
                    dispatch(handleOpenSnackbar({
                        message: 'Your Travel Alert Saved Successfully',
                        type: 'success',
                        open: true
                    }))
                }
            })
            handleSubscribeDialogClose()
        } else {
            handleSubscribeDialogClose()
            navigate('/login')
            dispatch(handleOpenSnackbar({
                message: 'You have to login first',
                type: 'error',
                open: true
            }))
        }
    }
    return (
        <>
            <FormHandler
                initialValues={initialValues}
                validationSchema={searchValidationSchema}
                onSubmit={handleSearch}
                isEditMode={false}
                hideActionButton={true}
            >
                {({ formik }) =>
                    <FlightsCheckSection {...props} formik={formik} />
                }
            </FormHandler >
            <DialogTeleport
                dialogOpen={subscribeDialog.isOpen}
                handleDialogClose={handleSubscribeDialogClose}
                dialogSize='md'
            >
                <SubscribeDialog state={state} handleSubscribeClick={handleSubscribeClick} data={subscribeDialog?.data} handleSubscribeDialogClose={handleSubscribeDialogClose} />
            </DialogTeleport>
        </>
    )
}

export default SearchFlightForm