import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequest } from "../utils/APIRequest";
import { apiRoutes } from "../main/constants/apiRoutes";

export const loginUser = createAsyncThunk('user/loginUser', async (data) => await APIRequest.post(apiRoutes.login, data))

export const signupUser = createAsyncThunk('user/signupUser', async (data) => await APIRequest.post(apiRoutes.signup, data))

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (data) => await APIRequest.post(apiRoutes.forgotPassword, data))

export const getUser = createAsyncThunk('user/getUser', async () => await APIRequest.get(apiRoutes.getUser))

export const resetPassword = createAsyncThunk('user/resetPassword', async (data) => await APIRequest.post(apiRoutes.resetPassword, data))

const initialState = {
    token: null,
    username: '',
    loading: {
        signupLoading: false,
        loginLoading: false,
        forgetPasswordLoading: false,
        resetPasswordLoading: false
    }
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.username = payload
            })
            .addCase(signupUser.pending, (state, { payload }) => {
                state.loading.signupLoading = true
            })
            .addCase(signupUser.fulfilled, (state, { payload }) => {
                state.loading.signupLoading = false
            })
            .addCase(signupUser.rejected, (state, { payload }) => {
                state.loading.signupLoading = false
            })
            .addCase(loginUser.pending, (state, { payload }) => {
                state.loading.loginLoading = true
            })
            .addCase(loginUser.fulfilled, (state, { payload }) => {
                state.loading.loginLoading = false
            })
            .addCase(loginUser.rejected, (state, { payload }) => {
                state.loading.loginLoading = false
            })
            .addCase(forgotPassword.pending, (state, { payload }) => {
                state.loading.forgetPasswordLoading = true
            })
            .addCase(forgotPassword.fulfilled, (state, { payload }) => {
                state.loading.forgetPasswordLoading = false
            })
            .addCase(forgotPassword.rejected, (state, { payload }) => {
                state.loading.forgetPasswordLoading = false
            })
            .addCase(resetPassword.pending, (state, { payload }) => {
                state.loading.resetPasswordLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, { payload }) => {
                state.loading.resetPasswordLoading = false
            })
            .addCase(resetPassword.rejected, (state, { payload }) => {
                state.loading.resetPasswordLoading = false
            })
    }
})
export const { setToken } = userSlice.actions
export default userSlice.reducer