import { configureStore } from "@reduxjs/toolkit"
import userSlice from "./userSlice"
import homeSlice from "./homeSlice"
import preferencesSlice from "./preferencesSlice"

export const store = configureStore({
    reducer: {
        user: userSlice,
        home: homeSlice,
        preferences: preferencesSlice
    },
})