import React, { useEffect, useState } from 'react';
import { Grid, Typography, IconButton, CircularProgress, useMediaQuery, Button, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deletePreference, getPreferences } from '../../store/preferencesSlice';
import { handleOpenSnackbar } from '../../store/homeSlice';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SearchFlightForm from '../home/shared-components/SearchFlightForm';
import Footer from '../shared-components/Footer';
import moment from 'moment';
import { getSourceDestination } from '../constants/homeConstants';
import DialogTeleport from '../shared-components/DataDialog/DialogTeleport';
import useDialogState from '../../utils/hooks/UseDialogState';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#1E1A54',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const Preferences = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.preferences)
    const homeState = useSelector((state) => state.home)
    const { token } = useSelector(state => state.user)
    const { dialogState: deleteConfirmationDialog, handleOpen: handleDeleteConfirmationDialogOpen, handleClose: handleDeleteConfirmationDialogClose } = useDialogState()
    const isSmallScreen = useMediaQuery('(max-width:1277px)');
    const preferencesColumn = isSmallScreen ? ['', 'From', 'To', 'Departure date', 'Arrival date', 'Class', 'People'] : ['From', 'To', 'Departure date', 'Arrival date', 'Class', 'People']
    // State to store the ID of the selected row
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        dispatch(getPreferences())
    }, [])

    const handleDeletePreference = () => {
        const formData = new FormData()
        formData.append('preference_id', deleteConfirmationDialog?.data?.id)
        dispatch(deletePreference(formData)).then((response) => {
            handleDeleteConfirmationDialogClose()
            dispatch(getPreferences()).then(() => dispatch(handleOpenSnackbar({
                open: true,
                type: 'success',
                message: 'Travel Alert deleted succesfully!'
            })))
        })
    }

    const handleRowClick = (preference) => {
        // Set the selected row ID
        isSmallScreen && setSelectedRowId(preference?.id === selectedRowId?.id ? null : preference);
    }

    return (
        <>
            <Grid className='page' item container rowGap={7} sx={{ paddingTop:{ xs : '5rem',sm: '6rem',md: '10rem' } }} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={11} md={11} lg={9}>
                    <SearchFlightForm state={homeState} token={token} />
                </Grid>
                <Grid item container spacing={2} columnGap={3} justifyContent={'center'}>
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography fontWeight={700} fontSize={25} color={'#1E1A54'}>Your Travel Alerts</Typography>
                    </Grid>
                    <Grid item xs={11} sm={11} md={10}>
                        <Paper sx={{ width: '100%', boxShadow: '10px 30px 70px 5px #00000012' }}>
                            {(!get(state, 'loading.preferencesLoading', false) && get(state, 'preferencesData', []).length > 0) && (
                                <TableContainer sx={{ overflowX: 'clip' }}>
                                    <Table stickyHeader aria-label="sticky table" >
                                        <TableHead sx={{ backgroundColor: 'red' }}>
                                            <TableRow>
                                                {preferencesColumn.map((column, index) => (
                                                    <StyledTableCell
                                                        key={index}
                                                        align={'center'}
                                                    >
                                                        <Typography>{column}</Typography>
                                                    </StyledTableCell>
                                                ))}
                                                <StyledTableCell
                                                    align={'right'}
                                                    style={{ minWidth: 50 }}
                                                >
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {get(state, 'preferencesData', []).map((preference, index) => (
                                                <>
                                                    <TableRow key={get(preference, 'id', index)} hover role="checkbox" tabIndex={-1}>
                                                        {isSmallScreen && <TableCell align={'left'}
                                                        >
                                                            <IconButton onClick={() => handleRowClick(preference)}>
                                                                {(isSmallScreen && selectedRowId?.id === get(preference, 'id')) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                            </IconButton>
                                                        </TableCell>}
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 170 }}
                                                        >
                                                            {getSourceDestination(get(preference, 'source', ''))?.city}, {getSourceDestination(get(preference, 'source', ''))?.state} ({getSourceDestination(get(preference, 'source', ''))?.code})
                                                        </TableCell>
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 170 }}
                                                        >
                                                            {getSourceDestination(get(preference, 'destination', ''))?.city}, {getSourceDestination(get(preference, 'destination', ''))?.state} ({getSourceDestination(get(preference, 'destination', ''))?.code})
                                                        </TableCell>
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 158 }}
                                                        >
                                                            {get(preference, 'departure_date', false) ? moment(get(preference, 'departure_date')).format('l') : '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 158 }}
                                                        >
                                                            {get(preference, 'arrival_date', false) ? moment(get(preference, 'arrival_date')).format('l') : '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 140 }}
                                                        >
                                                            {get(preference, 'ticket_class', '-')}
                                                        </TableCell>
                                                        <TableCell
                                                            align={'center'}
                                                            style={{ minWidth: 150 }}
                                                        >
                                                            {get(preference, 'number_of_passengers', '-')}
                                                        </TableCell>
                                                        <TableCell
                                                            align={'right'}
                                                            style={{ minWidth: 50 }}
                                                        >
                                                            <IconButton onClick={() => handleDeleteConfirmationDialogOpen({ id: get(preference, 'id') })} aria-label="delete">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    {(isSmallScreen && selectedRowId?.id === get(preference, 'id')) && (
                                                        <TableRow>
                                                            <TableCell colSpan={7}>
                                                                <Grid container maxWidth={{ xs: '85vw', sm: '86vw', md: '80vw' }} spacing={2} className='py-5'>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>From</Grid>
                                                                        <Grid item>{getSourceDestination(get(selectedRowId, 'source', ''))?.city}, {getSourceDestination(get(selectedRowId, 'source', ''))?.state} ({getSourceDestination(get(selectedRowId, 'source', ''))?.code})</Grid>
                                                                    </Grid>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>To</Grid>
                                                                        <Grid item>{getSourceDestination(get(selectedRowId, 'destination', ''))?.city}, {getSourceDestination(get(selectedRowId, 'destination', ''))?.state} ({getSourceDestination(get(selectedRowId, 'destination', ''))?.code})</Grid>
                                                                    </Grid>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>Departure date</Grid>
                                                                        <Grid item>{get(selectedRowId, 'departure_date', false) ? moment(get(selectedRowId, 'departure_date')).format('l') : '-'}</Grid></Grid>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>Arrival date</Grid>
                                                                        <Grid item>{get(selectedRowId, 'arrival_date', false) ? moment(get(selectedRowId, 'arrival_date')).format('l') : '-'}</Grid>
                                                                    </Grid>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>Class</Grid>
                                                                        <Grid item>{get(selectedRowId, 'ticket_class', '-')}</Grid>
                                                                    </Grid>
                                                                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Grid item fontWeight={'bold'}>People</Grid>
                                                                        <Grid item>{get(selectedRowId, 'number_of_passengers', '-')}</Grid>
                                                                    </Grid>
                                                                    <Grid item container justifyContent={'end'} alignItems={'center'}>
                                                                        <Grid item><Button sx={{
                                                                            paddingLeft: '15px',
                                                                            paddingRight: '15px',
                                                                            borderRadius: '20px',
                                                                            fontSize: { xs: 14 },
                                                                            '&:hover': {
                                                                                backgroundColor: '#FBAD18',
                                                                                color: 'white',
                                                                            },
                                                                            '&:disabled': {
                                                                                backgroundColor: 'gray',
                                                                                color: 'black'
                                                                            },
                                                                            backgroundColor: '#1E1A54',
                                                                            color: 'white',
                                                                            textTransform: 'none',
                                                                        }} onClick={() => handleDeleteConfirmationDialogOpen({ id: get(selectedRowId, 'id') })} aria-label="delete">
                                                                            Delete
                                                                        </Button></Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {(get(state, 'preferencesData', []).length === 0 && !get(state, 'loading.preferencesLoading', false)) && (
                                <Grid item container className='py-10'  rowSpacing={2}>
                                    <Grid item display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} rowGap={1} xs={12} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item sx={{ marginBottom: { xs: '5px', sm: 0 } }}>
                                            <InfoIcon sx={{ color: '#1E1A54', fontSize: 40 }} />&nbsp;
                                        </Grid>
                                        <Grid item>
                                            <Typography color='#1E1A54' fontSize={18} fontWeight={600}>You have No Travel Alerts Yet!!</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item display={'flex'} xs={12} justifyContent={'center'} alignItems={'center'}>
                                        <Button LinkComponent={Link} sx={{
                                            backgroundColor: '#1E1A54',
                                            borderRadius: '20px',
                                            padding: '8px 18px',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#FBAD18',
                                                color: 'white',
                                            },
                                            '&:disabled': {
                                                backgroundColor: 'gray',
                                                color: 'black'
                                            },
                                            textTransform: 'none'
                                        }}
                                            href="#setupTravelAlert">Set Up Travel Alert</Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    {get(state, 'loading.preferencesLoading', false) && (
                        <Grid item container xs={12} spacing={2} sx={{ height: '200px' }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item><CircularProgress /></Grid>
                            <Grid item><Typography color='#1E1A54' fontSize={18} fontWeight={600}>Loading....</Typography></Grid>
                        </Grid>
                    )}
                </Grid >
            </Grid >
            <DialogTeleport
                dialogOpen={deleteConfirmationDialog.isOpen}
                handleDialogClose={handleDeleteConfirmationDialogClose}
                dialogSize='xs'
                dialogTitle="Delete Travel alert" dialogAction={{ disabled: false, label: 'Delete', function: handleDeletePreference }}
            >
                <Grid container className='py-5'>
                    <Typography>
                        Are you sure you want to delete this travel alert.
                    </Typography>
                </Grid>
            </DialogTeleport>
            <Footer />
        </>
    )
}

export default Preferences;