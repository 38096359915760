import { useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    MobileStepper,
    Slide,
    Theme,
    Tooltip,
    TooltipProps,
    Zoom,
    tooltipClasses,
    useMediaQuery,
} from '@mui/material'
import {
    HelpOutline as HelpOutlineIcon, KeyboardArrowLeft, KeyboardArrowRight
} from '@mui/icons-material'
import DialogCloseButton from './DialogCloseButton'
import { styled, useTheme } from '@mui/system'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@mui/styles'
import React from 'react'



const TransitionForFullscreen = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    dialogPaper: {
        borderRadius: '15px !important',
    },
});

export default function DialogTeleport({
    dialogOpen,
    handleDialogClose,
    dialogTitle,
    dialogAction,
    dialogSize = 'xs',
    isFullWidth = true,
    children,
    scroll = 'paper',
    dialogTitleTooltip = false,
    stepperAction,
    tooltipDescription = "",
    fullScreen,
}) {
    const theme = useTheme();
    const classes = useStyles();

    const DarkTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            background: 'rgba(32, 32, 32, 0.9) !important',
            borderRadius: '0.5rem !important',
        },
    }))

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            maxWidth={dialogSize}
            fullWidth={isFullWidth}
            scroll={scroll}
            classes={{ paper: classes.dialogPaper }}
            TransitionComponent={fullScreen ? TransitionForFullscreen : Zoom}
        >
            <DialogCloseButton handleClose={handleDialogClose} />

            {!!dialogTitle && <DialogTitle
                sx={{
                    background: "#1E1A54",
                    color: "#fff"
                }}>
                {dialogTitle}
                {
                    dialogTitleTooltip && < DarkTooltip
                        title={tooltipDescription}
                        placement={isMobile ? 'bottom-start' : 'right'}
                    >
                        <HelpOutlineIcon sx={{ ml: 1.5, color: 'text.light' }} />
                    </DarkTooltip >
                }
            </DialogTitle>}

            <DialogContent>{children}</DialogContent>

            {!stepperAction && (
                <DialogActions>
                    {fullScreen && <Button sx={{ borderRadius: '20px' }} onClick={handleDialogClose}>Cancel</Button>}

                    {dialogAction && (
                        <>
                            <Button sx={{ color: '#1E1A54', borderRadius: '20px' }} onClick={handleDialogClose} >Cancel</Button>
                            <Button
                                sx={{
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#FBAD18',
                                        color: 'white',
                                    },
                                    '&:disabled': {
                                        backgroundColor: 'gray',
                                        color: 'black'
                                    },
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                                onClick={dialogAction.function}
                                disabled={dialogAction.disabled}
                                isLoading={dialogAction?.isLoading}
                            >
                                {dialogAction.label}
                            </Button>
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}



/**
 * DialogTeleport combines default Dialog properties and style and a given component. It's an easy and fast way to show your container components inside dialogs.
 */

// export type DialogTeleportProps = {
//   dialogOpen: boolean
//   handleDialogClose: () => void
//   dialogTitle: string | any
//   dialogAction?: {
//     label: string
//     function(): any
//     disabled?: boolean
//     isLoading?: boolean
//   } | null
//   dialogSize: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
//   isFullWidth?: boolean
//   scroll?: 'body' | 'paper'
//   dialogTitleTooltip?: boolean
//   tooltipDescription?: any
//   children: ReactNode
// stepperAction={
//   maxSteps: 2,
//   activeStep,
//   stepHandle: stepHandle,
//   stepperHandleClick: stepperHandleClick,
//   isLoading: false,
//   stepperLeble: 'Submit Application',
// }
// }