import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Home from './main/home/Home';
import LoginPage from './main/login/Login';
import Sign_upPage from './main/signup/Signup';
import Navbar from './main/shared-components/Navbar';
import ForgotPassword from './main/forgot-password/ForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { handleCloseSnackbar } from './store/homeSlice';
import ResetPassword from './main/reset-password/ResetPassword';
import Preferences from './main/preferences/Preferences';
import RequiredAuth from './main/shared-components/RequiredAuth'
import { useEffect } from 'react';
import { getUser, setToken } from './store/userSlice';

function App() {
  const { snackbar } = useSelector(state => state.home)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(setToken(localStorage.getItem('Token')))
    dispatch(getUser())
    localStorage.getItem('Token') && navigate('/')
  }, [])
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar?.open} autoHideDuration={3000} onClose={() => dispatch(handleCloseSnackbar())}>
        <Alert
          autoHideDuration={3000}
          onClose={() => dispatch(handleCloseSnackbar())}
          severity={snackbar?.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<Sign_upPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path='/reset-password-confirm' element={<ResetPassword />} />
        <Route path='/travel-alerts' element={<RequiredAuth><Preferences /></RequiredAuth>} />
      </Routes>
    </>
  );
}

export default App;
