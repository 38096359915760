import React, { useState } from 'react'
import Card from '@mui/material/Card';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, Menu, MenuItem, Paper, Popper, TextField, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { classes, noOfPassengers, tripMode } from '../../constants/homeConstants';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SearchIcon from '@mui/icons-material/Search';
import { get } from 'lodash'
import { useDispatch } from 'react-redux';
import { setDestinationAirportData, setSourceAirportData } from '../../../store/homeSlice';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles'
import dayjs from 'dayjs';

const getNextYear = () => {
    return dayjs().add(11, 'month').startOf('month');
};

const useStyles = makeStyles({
    datePicker: {
        '& .MuiOutlinedInput-root': {
            border: 'none', // Remove border from the input
            padding: 0,
            fontSize: 15
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important', // Remove border outline
        },
        '& .MuiOutlinedInput-input': {
            padding: 0, // Remove padding from the input
        },
    },
    autocomplete: {
        '& .MuiInput-root': {
            border: 'none !important', // Remove border from the input
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            borderBottom: 'none',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:last-child': {
            borderBottom: 'none',
        },
    },
    input: {
        border: 'none !important',
    },
    iconButton: {
        '& .MuiIconButton-root': {
            padding: '0 !important'
        }
    }
});

const FlightsCheckSection = (props) => {
    const nextYear = getNextYear();
    const { formik, state } = props;
    const [tripModeAnchorEl, setTripModeAnchorEl] = useState(null);
    const [passengerAnchorEl, setPassengerAnchorEl] = useState(null);
    const [classAnchorEl, setClassAnchorEl] = useState(null);
    const muiClasses = useStyles();
    const dispatch = useDispatch()
    const handleTripModeClick = (event) => {
        setTripModeAnchorEl(event.currentTarget);
    };

    const handlePassengerClick = (event) => {
        setPassengerAnchorEl(event.currentTarget);
    };

    const handleClassClick = (event) => {
        setClassAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setTripModeAnchorEl(null);
        setPassengerAnchorEl(null);
        setClassAnchorEl(null)
    };

    const handleSelectTripMode = (value) => {
        formik.setFieldValue('trip', value);
        handleClose();
    };

    const handleSelectPassenger = (value) => {
        formik.setFieldValue('number_of_passengers', value);
        handleClose();
    };

    const handleSelectClass = (value) => {
        formik.setFieldValue('class', value);
        handleClose();
    }

    const handleToggleCountry = () => {
        const sourceValue = formik.values.source
        formik.setFieldValue('source', formik.values.destination)
        dispatch(setDestinationAirportData(formik.values.destination))
        formik.setFieldValue('destination', sourceValue)
        dispatch(setSourceAirportData(sourceValue))
    }

    const sourceFilterOptions = (options, state) => {
        let codeMatches = [];
        let cityMatches = [];

        options.forEach((element) => {
            const searchTerm = formik.values.searchSource?.toLowerCase();
            const cityMatch = element?.city?.toLowerCase().includes(searchTerm);
            const codeMatch = element?.code?.toLowerCase().includes(searchTerm);

            if (codeMatch) {
                codeMatches.push(element);
            } else if (cityMatch) {
                cityMatches.push(element);
            }
        });

        const combinedMatches = [...codeMatches, ...cityMatches];
        return combinedMatches.slice(0, 4);
    };
    const destinationFilterOptions = (options, state) => {
        let codeMatches = [];
        let cityMatches = [];
        options.forEach((element) => {
            const searchTerm = formik.values.searchDestination?.toLowerCase();
            const cityMatch = element?.city?.toLowerCase().includes(searchTerm);
            const codeMatch = element?.code?.toLowerCase().includes(searchTerm);

            if (codeMatch) {
                codeMatches.push(element);
            } else if (cityMatch) {
                cityMatches.push(element);
            }
        });
        const combinedMatches = [...codeMatches, ...cityMatches];
        return combinedMatches.slice(0, 4);
    };
    return (
        <Card sx={{ borderRadius: { lg: '100px', md: '100px', sm: '0px' }, border: { lg: 'none', sm: '1px solid #DEE6FF', xs: '1px solid #DEE6FF' }, boxShadow: '10px 0px 70px 30px #00000012', width: '100%' }}>
            <Grid container spacing={2} sx={{ padding: { xs: "1.25rem 1rem", sm: "1.25rem 2.5rem" } }}>
                <Grid item container xs={12} spacing={2} alignItems={'center'}>
                    <Grid item sx={{ paddingLeft: { xs: 0, lg: '10px !important' } }}>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                padding: '6px 10px',
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px',
                                width: '130px',
                                justifyContent: 'space-between'
                            }}
                            aria-controls={tripModeAnchorEl ? 'trip-mode-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={tripModeAnchorEl ? 'true' : undefined}
                            onClick={handleTripModeClick}
                        >
                            {formik.values.trip?.label}
                        </Button>
                        <Menu
                            id="trip-mode-menu"
                            anchorEl={tripModeAnchorEl}
                            open={Boolean(tripModeAnchorEl)}
                            onClose={handleClose}
                        >
                            {tripMode.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectTripMode(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                padding: '6px 10px',
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px',
                                width: '150px',
                                justifyContent: 'space-between'
                            }}
                            aria-controls={passengerAnchorEl ? 'passenger-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={passengerAnchorEl ? 'true' : undefined}
                            onClick={handlePassengerClick}
                        >
                            {`${formik.values.number_of_passengers} Passenger${formik.values.number_of_passengers !== 1 ? 's' : ''}`}
                        </Button>
                        <Menu
                            id="passenger-menu"
                            anchorEl={passengerAnchorEl}
                            open={Boolean(passengerAnchorEl)}
                            onClose={handleClose}
                        >
                            {noOfPassengers.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectPassenger(item.value)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                padding: '6px 10px',
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px',
                                width: '115px',
                                justifyContent: 'space-between'
                            }}
                            aria-controls={classAnchorEl ? 'class-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={classAnchorEl ? 'true' : undefined}
                            onClick={handleClassClick}
                        >
                            {formik.values.class?.label}
                        </Button>
                        <Menu
                            id="class-menu"
                            anchorEl={classAnchorEl}
                            open={Boolean(classAnchorEl)}
                            onClose={handleClose}
                        >
                            {classes.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectClass(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={<Checkbox name="flexibleDates" value={formik.values.flexibleDates} checked={formik.values.flexibleDates} onChange={formik.handleChange} />}
                                label={'Flexible Dates'}
                                sx={{ color: '#1E1A54' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container xs={12} alignItems={'center'} rowSpacing={2} spacing={2} className='px-4'>
                    <Grid item container xs={12} rowSpacing={3} alignItems={'start'} justifyContent={'space-between'}>
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} md={2.5} lg={3}>
                            <Typography color='#302842' fontWeight={500}>Origin</Typography>
                            <Autocomplete
                                className='autocomplete'
                                key={formik.values.source}
                                freeSolo
                                filterOptions={sourceFilterOptions}
                                value={formik.values.source}
                                options={get(state, 'sourceAirportData', [])}
                                getOptionLabel={(option) => `${option?.city}, ${option.code}`}
                                onChange={(_, newValue) => {
                                    dispatch(setDestinationAirportData(newValue))
                                    formik.setFieldValue('source', newValue)
                                }}
                                onInputChange={(event, newValue) => {
                                    formik.setFieldValue('searchSource', newValue)
                                }}
                                onBlur={() => formik.setFieldTouched('source', true)}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component='li'
                                        sx={{
                                            '& > img': { mr: 3, flexShrink: 0 },
                                            '&.MuiAutocomplete-option[aria-selected="true"]': {
                                                backgroundColor: 'transparent',
                                                width: '100%',
                                            },
                                        }}
                                        {...props}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={'bold'} fontSize={16}>{`${get(option, 'city')}, ${get(option, 'state')}, ${get(option, 'country')}, ${get(option, 'code')}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={14} color='gray'>{get(option, 'name')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        name='source'
                                        placeholder='Enter your origin'
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={1} md={0}>
                            <Button
                                onClick={handleToggleCountry}
                                sx={{
                                    color: 'black',
                                    textTransform: 'none',
                                }}><SyncAltIcon /></Button>
                        </Grid>
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} md={2.5} lg={3}>
                            <Typography color='#302842' fontWeight={500}>Destination</Typography>
                            <Autocomplete
                                className='autocomplete'
                                key={formik.values.destination}
                                freeSolo
                                filterOptions={destinationFilterOptions}
                                value={formik.values.destination}
                                options={get(state, 'destinationAirportData', [])}
                                getOptionLabel={(option) => `${option?.name}, ${option?.city}, ${option?.country}, ${option.code}`}
                                onChange={(_, newValue) => {
                                    formik.setFieldValue('destination', newValue)
                                    dispatch(setSourceAirportData(newValue))
                                }}
                                onInputChange={(event, newValue) => {
                                    formik.setFieldValue('searchDestination', newValue)
                                }}
                                onBlur={() => formik.setFieldTouched('destination', true)}
                                PopperComponent={(props) => (
                                    <Popper {...props} style={{ zIndex: 99999 }}>
                                        <Paper {...props} />
                                    </Popper>
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component='li'
                                        sx={{
                                            '& > img': { mr: 3, flexShrink: 0 },
                                            '&.MuiAutocomplete-option[aria-selected="true"]': {
                                                backgroundColor: 'transparent',
                                                width: '100%',
                                            },
                                        }}
                                        {...props}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={'bold'} fontSize={16}>{`${get(option, 'city')}, ${get(option, 'state')}, ${get(option, 'country')}, ${get(option, 'code')}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={14} color='gray'>{get(option, 'name')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='destination'
                                        variant='standard'
                                        placeholder='Enter your destination'
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Divider orientation="vertical" variant="middle" sx={{ marginRight: '5px' }} flexItem />
                        {!formik.values.flexibleDates && <><Grid item sx={{ cursor: 'pointer', marginRight: { md: '5px', xs: '0px' } }} xs={12} md={2.5} lg={2}>
                            <Typography color='#302842' fontWeight={500}>Departure</Typography>
                            <div className="datepicker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer sx={{ paddingRight: '10px' }} components={['DatePicker']}>
                                        <DatePicker sx={{ width: { lg: '120px', md: '100px' } }} minDate={dayjs()} className={`${muiClasses.datePicker} ${muiClasses.iconButton}`} onChange={(newValue) => formik.setFieldValue('departure_date', newValue)} value={formik.values.departure_date} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                            <Grid item sx={{ cursor: 'pointer' }} xs={12} md={2.5} lg={2}>
                                <Typography color='#302842' fontWeight={500}>Return</Typography>
                                <div className="datepicker">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer sx={{ paddingRight: '10px' }} className='date-input' components={['DatePicker']}>
                                            <DatePicker minDate={formik.values.departure_date} disabled={formik.values.trip?.value != 'Round_Trip'} sx={{ width: { lg: '120px', md: '90px' } }} className={`${muiClasses.datePicker} ${muiClasses.iconButton}`} onChange={(newValue) => formik.setFieldValue('arrival_date', newValue)} value={formik.values.arrival_date} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Grid></>}
                        {formik.values.flexibleDates && <Grid item sx={{ cursor: 'pointer' }} xs={12} md={2.5} lg={2}>
                            <Typography color='#302842' fontWeight={500}>Month</Typography>
                            <div className="datepicker month-picker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer sx={{ paddingRight: '10px' }} components={['DatePicker']}>
                                        <DatePicker sx={{ width: { xs: '130px' } }} className={`${muiClasses.datePicker} ${muiClasses.iconButton}`} onChange={(newValue) => formik.setFieldValue('month', newValue)} value={formik.values.month} openTo="month" views={['month', 'year']} minDate={dayjs()} maxDate={nextYear} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </Grid>}
                        <Grid item display={'flex'} sx={{ paddingTop: { lg: '0px !important', xs: '16px' } }} alignSelf={'center'}>
                            {get(state, 'loading.milezLoading') ? <CircularProgress /> : <Button
                                onClick={() => formik.handleSubmit()}
                                disabled={Object.keys(formik.errors).length > 0}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#FBAD18',
                                        color: 'white',
                                    },
                                    '&:disabled': {
                                        backgroundColor: 'gray',
                                        color: 'black'
                                    },
                                    padding: '15px',
                                    width: '50px',
                                    height: '50px',
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                    textTransform: 'none',
                                    borderRadius: '50%',
                                    minWidth: 0,
                                }}
                            >
                                <SearchIcon />
                            </Button>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default FlightsCheckSection