import { Avatar, Button, Grid, TextField, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import FormHandler from '../shared-components/FormHandler';
import * as yup from 'yup'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenSnackbar } from '../../store/homeSlice';
import { checkPasswordUniqueness } from '../constants/navbarConstants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../store/userSlice';
import { get } from 'lodash';
function ResetPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const { loading } = useSelector((state) => state.user)
    const tokenFromQuery = searchParams.get('token');
    const initialValues = {
        password: '',
        confirmPassword: ''
    }
    const validationSchema = yup.object({
        password: yup.string()
            .required('Required')
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Password must contain at least one letter, one number, and one special character'
            ),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
    })
    const handleResetPassword = ({ values, actions }) => {
        const payload = {
            password: values.password,
            token: tokenFromQuery
        }
        const formData = new FormData()
        Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
        });
        dispatch(resetPassword(formData)).then((response) => {
            if (response?.error) {
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'error',
                    message: 'Something went wrong!'
                }))
            } else {
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'success',
                    message: 'Password Reset Succesfully!'
                }))
                navigate('/login')
            }
        })
    }
    return (
        <FormHandler
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleResetPassword}
            isEditMode={false}
            hideActionButton={true}
        >
            {({ formik }) => {
                return (
                    <Grid display={'flex'} alignItems={'center'} sx={{ backgroundImage: `url('images/image 19.png')`, backgroundSize: 'cover', minHeight: '100dvh', paddingLeft: { xs: '20px', sm: '10%' }, padding:'10% 20px' }}>
                        <Grid container columnSpacing={3} rowSpacing={2} lg={4} md={6} sm={8} xs={12} sx={{ backgroundColor: 'white', boxShadow: '0 0 10px 0 gray', color: 'black', padding: '20px 20px 20px 0px', borderRadius: '10px',margin:0 }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} className='text-center'>
                                    <Typography className='text-center' fontWeight={700} color='#1E1A54' fontSize={25}>Reset Password!</Typography>
                                </Grid>
                                <Grid item xs={12} className='text-center'>
                                    <Typography fontSize={16} sx={{ textAlign: 'center' }} color='#1E1A54'>Set The new password for your Account So you can login and Access all the features.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        name="password"
                                        helperText={formik.touched.password && formik.errors.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        htmlFor="Password"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label="Confirm Password"
                                        variant="outlined"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        name="confirmPassword"
                                        htmlFor="confirmPassword"
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FBAD18',
                                            color: 'white',
                                        }, backgroundColor: '#1E1A54'
                                    }}
                                    fullWidth
                                    onClick={() => formik.handleSubmit()}
                                    type='submit'
                                >
                                    Submit &nbsp; {get(loading, 'resetPasswordLoading', false) && <CircularProgress size={20} />}
                                </Button>
                            </Grid>
                            <Grid item xs={12} container justifyContent={'center'} padding={'20px'}>
                                <Typography color={'#49475A'} sx={{ cursor: 'pointer' }} display={'flex'} gap={1} onClick={() => navigate('/login')}>
                                    <ArrowBackIcon /> Back to login
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            }
        </FormHandler>
    )
}

export default ResetPassword;
