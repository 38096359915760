export const apiRoutes = {
    login: 'login',
    signup: 'register',
    forgotPassword: 'password_reset/',
    savePreferences: 'user/flight-preferences',
    getUser: 'fullname',
    getMiles: 'milezAPI',
    resetPassword: 'password_reset/confirm/',
    preferences: 'user/flight-preferences',
    airportInfo: 'add-airport-info'
}