import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { airportData } from "../main/constants/airportData";
import { APIRequest } from "../utils/APIRequest";
import { apiRoutes } from "../main/constants/apiRoutes";

export const savePreferences = createAsyncThunk('home/savePreferences', async (data) => await APIRequest.post(apiRoutes.savePreferences, data))

export const getMilezData = createAsyncThunk('home/getMilezData', async (data) => await APIRequest.post(apiRoutes.getMiles, data))

export const addAirportInfo = createAsyncThunk('home/addAirportInfo', async (data) => await APIRequest.post(apiRoutes.airportInfo, data))

const initialState = {
    snackbar: {
        open: false,
        message: '',
        type: 'success'
    },
    milezData: {},
    sourceAirportData: [...airportData],
    destinationAirportData: [...airportData],
    loading: {
        milezLoading: false
    }
}
const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        handleOpenSnackbar: (state, action) => {
            state.snackbar = action.payload
        },
        handleCloseSnackbar: (state, action) => {
            state.snackbar = {
                ...state.snackbar,
                open: false,
            }
        },
        setSourceAirportData: (state, action) => {
            state.sourceAirportData = [...state.sourceAirportData].filter(({ city }) => city != action?.payload?.city)
        },
        setDestinationAirportData: (state, action) => {
            state.destinationAirportData = [...state.destinationAirportData].filter(({ city }) => city != action?.payload?.city)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMilezData.fulfilled, (state, { payload }) => {
                state.milezData = payload
                state.loading.milezLoading = false
            })
            .addCase(getMilezData.pending, (state, { payload }) => {
                state.milezData = payload
                state.loading.milezLoading = true
            })
            .addCase(getMilezData.rejected, (state, { payload }) => {
                state.loading.milezLoading = false
            })
    }
})
export const { setSourceAirportData, setDestinationAirportData, handleOpenSnackbar, handleCloseSnackbar } = homeSlice.actions
export default homeSlice.reducer