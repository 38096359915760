import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIRequest } from '../utils/APIRequest'
import { apiRoutes } from '../main/constants/apiRoutes'

export const getPreferences = createAsyncThunk('preferences/getPreferences', async () => await APIRequest.get(apiRoutes.preferences))

export const deletePreference = createAsyncThunk('preferences/deletePreference', async (data) => await APIRequest.remove(apiRoutes.preferences, data))

const initialState = {
    preferencesData: [],
    loading: {
        preferencesLoading: false
    }
}
const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreferences.pending, (state, { payload }) => {
                state.loading.preferencesLoading = true
            })
            .addCase(getPreferences.fulfilled, (state, { payload }) => {
                state.preferencesData = payload
                state.loading.preferencesLoading = false
            })
    }
})

export const { } = preferencesSlice.actions
export default preferencesSlice.reducer