import { Grid } from '@mui/material';
import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../store/userSlice';
import { pages, settings, logout } from '../constants/navbarConstants';

function Navbar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const activeTab = pathname == '/' ? 'Home' : pathname == '/login' ? 'Login' : pathname == '/signup' ? 'Signup' : 'Travel Alerts'
    const { token, username } = useSelector(state => state.user)
    const handleNavigate = (page) => {
        if (page == 'Login') {
            navigate('/login')
            handleCloseNavMenu()
        } else if (page == 'Signup') {
            navigate('/signup')
            handleCloseNavMenu()
        } else if (page == 'Home') {
            navigate('/')
            handleCloseNavMenu()
        } else if (page == 'Travel Alerts') {
            navigate('/travel-alerts')
            handleCloseNavMenu()
        } else {
            navigate('/')
            handleCloseNavMenu()
        }
    }
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page = null) => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (setting) => {
        setAnchorElUser(null);
        if (setting == 'Logout') {
            dispatch(setToken(null))
            localStorage.removeItem('Token')
            navigate('/')
        } else if (setting == 'Preferences') {
            navigate('/preferences')
        }
    };
    return (
        <AppBar sx={{ backgroundColor: '#FFFFFF', position: 'absolute', top: 0 }}>
            {/* <Container maxWidth="xl"> */}
                <Toolbar sx={{padding:'0 30px'}} disableGutters>
                    <Grid container alignItems={'center'}>
                        <Grid item alignItems={'center'} container xs={10} sm={8} md={4}>
                            <Avatar onClick={() => navigate('/')} maxWidth sx={{ cursor: 'pointer', display: { xs: 'none', md: 'flex' }, height: '100%', width: '200px', borderRadius: 0 }} alt="Logo" src={'images/Logo.png'} />
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                {token && <><IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="black"
                                >
                                    <MenuIcon />
                                </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        {pages.map((page) => (
                                            <MenuItem key={page} onClick={() => handleNavigate(page)}>
                                                <Typography textAlign="center">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu></>}
                                {!token && <><IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="black"
                                >
                                    <MenuIcon />
                                </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        {logout.map((page) => (
                                            <MenuItem key={page} onClick={() => handleNavigate(page)}>
                                                <Typography textAlign="center">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu></>}
                            </Box>
                            <Avatar maxWidth sx={{ display: { xs: 'flex', md: 'none' }, height: '100%', width: '150px', borderRadius: 0 }} alt="Logo" src={'images/Logo.png'} />
                        </Grid>
                        {token && <Grid xs={1} justifyContent={'center'} sx={{ display: { xs: 'none', md: 'flex' } }} sm={1} md={4} item container >
                            {pages.map((page) => (
                                <Button
                                    onClick={() => handleNavigate(page)}
                                    key={page}
                                    sx={{ '&:hover': { color: 'gray' }, my: 2, color: 'white', display: 'block', color: activeTab == page ? 'white' : '#828282', textTransform: 'none', marginRight: '15px', bgcolor: activeTab == page && '#FBAD18' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Grid>}
                        {token && <Grid xs={2} sm={4} md={4} item container justifyContent='end'>
                            <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                <Tooltip title="Open settings">
                                    <Grid container alignItems={'center'}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={username} src="/static/images/avatar/2.jpg" />
                                        </IconButton>
                                        <Typography display={'flex'} fontSize={{ xs: 0, md: 16 }} paddingLeft={'5px'} color='#1E1A54' fontWeight={'bold'}>{username}</Typography>
                                    </Grid>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Grid>}
                        {!token && <Grid md={8} item container justifyContent={'end'}>
                            <Box sx={{ flexGrow: 1, justifyContent: 'end', columnGap: '10px', display: { xs: 'none', md: 'flex' } }}>
                                {logout.map((page) => (
                                    <Button
                                        key={page}
                                        onClick={() => handleNavigate(page)}
                                        sx={{
                                            '&:hover': {
                                                color: 'gray'
                                            }, my: 2, display: 'block', textTransform: 'none', bgcolor: activeTab == page && '#FBAD18', color: activeTab == page ? 'white' : '#828282'
                                        }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>}
                    </Grid>
                </Toolbar>
            {/* </Container> */}
        </AppBar>
    )
}

export default Navbar;
