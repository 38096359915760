import { Avatar, Button, Grid, TextField, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import FormHandler from '../shared-components/FormHandler';
import * as yup from 'yup'
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getUser, loginUser, setToken } from '../../store/userSlice';
import { get } from 'lodash';
function LoginPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.user)
    const initialValues = {
        email: '',
        password: ''
    }
    const loginValidationSchema = yup.object({
        email: yup.string().email('Invalid email').required('Required'),
        password: yup.string().required('Required')
    })
    const handleLogin = ({ values, actions }) => {
        const formData = new FormData()
        Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
        });
        dispatch(loginUser(formData)).then((response) => {
            if (response?.error) {
                actions.setErrors({ email: response?.error?.message })
            } else {
                localStorage.setItem('Token', response.payload?.token)
                dispatch(setToken(response.payload?.token))
                dispatch(getUser())
                navigate('/travel-alerts')
            }
        })
    }
    return (
        <FormHandler
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleLogin}
            isEditMode={false}
            hideActionButton={true}
        >
            {({ formik }) => {
                return (
                    <Grid container alignItems={'center'} sx={{ backgroundImage: `url('images/image 19.png')`, backgroundSize: 'cover', minHeight: '100vh',paddingLeft: { xs: '20px', sm: '10%' },padding:'10% 20px' }}>
                        <Grid container columnSpacing={3} rowSpacing={1} lg={4} md={6} sm={8} xs={12} sx={{ backgroundColor: 'white', boxShadow: '0 0 10px 0 gray', color: 'black', padding: '20px 20px 20px 0px', borderRadius: '10px',margin:0 }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} className='text-center'>
                                    <Typography className='text-center' fontWeight={700} color='#1E1A54' fontSize={25}>Welcome Back!</Typography>
                                </Grid>
                                <Grid item xs={12} className='text-center'>
                                    <Typography color='#1E1A54' fontSize={16} sx={{ textAlign: 'center' }}  >Login to setup your travel alerts to get the best deal in Award Travel</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        name='email'
                                        fullWidth
                                        label="Email"
                                        required
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name='password'
                                        label="Password"
                                        type="password"
                                        required
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent={'end'}>
                                <Typography variant="body2">
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FBAD18',
                                            color: 'white',
                                        }, backgroundColor: '#1E1A54',
                                        textTransform: 'none'
                                    }}
                                    fullWidth
                                    onClick={() => formik.handleSubmit()}
                                    type='submit'
                                >
                                    Sign in  &nbsp; {get(loading, 'loginLoading', false) && <CircularProgress size={20} />}
                                </Button>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'center'}>
                                <Typography color='#1E1A54' variant="body2">
                                    Don't have an account? <Link to="/signup" >Sign up</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            }
        </FormHandler>
    )
}

export default LoginPage;
