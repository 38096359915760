export const BASE_URL = 'https://award-api.exerboost.in:8000';

const handleResponse = async (response) => {
    if (!response.ok) {
        const error = await response.json();
        console.log("🚀 ~ file: APIRequest.js:9 ~ handleResponse ~ error:", error)
        throw error.error || 'Something went wrong';
    }
    return response.json();
};

const getBody = (data) => (data instanceof FormData) ? data : JSON.stringify(data)

export const getAccessToken = () => {
    return window.localStorage.getItem('Token');
};

const getHeaders = (body = {}) => {
    // You can customize headers based on your needs (e.g., authentication headers)
    return {
        ...(getAccessToken() && { Authorization: `Token ${getAccessToken()}` }),
        ...(!(body instanceof FormData) && {
            'Content-Type': 'application/json',
        })
    };
};

const buildQueryString = (params) => {
    return Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
};


export const APIRequest = {

    get: async (endpoint, params = {}) => {
        const queryString = buildQueryString(params);
        const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        return handleResponse(response);
    },

    post: async (endpoint, data, params = {}) => {
        const queryString = buildQueryString(params);
        const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
            method: 'POST',
            headers: getHeaders(data),
            body: getBody(data),
        });

        return handleResponse(response);
    },

    put: async (endpoint, data, params = {}) => {
        const queryString = buildQueryString(params);
        const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
            method: 'PUT',
            headers: getHeaders(data),
            body: getBody(data),
        });

        return handleResponse(response);
    },

    patch: async (endpoint, data, params = {}) => {
        const queryString = buildQueryString(params);
        const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
            method: 'PATCH',
            headers: getHeaders(data),
            body: getBody(data),
        });

        return handleResponse(response);
    },

    remove: async (endpoint, data, params = {}) => {
        const queryString = buildQueryString(params);
        const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
            method: 'DELETE',
            headers: getHeaders(data),
            body: getBody(data),
        });

        return handleResponse(response);
    }
}
